import React, { useEffect, useState, useRef } from 'react';
import './navbar.scss';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import useSound from 'use-sound';
import cores from '../../componentes/cores/Cores'
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import { Menu } from '@mui/icons-material';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import logos from "./logos.png";
import { Link } from "react-router-dom";
import FourStarRating from '../starRating/StarRating';
import { getCategory, getLimiteSimultaneo, getFluxo, getCategoryLimSim, verificaEmpresaRecarga } from '../../api/client';
import { useQuery } from 'react-query';
import MenuLateral from '../menuLateral/Menulateral';

const NavBar = (prop) => {
  const enableSound = JSON.parse(localStorage.getItem("soundPreference")) || false;
  const usuarioCash = JSON.parse(localStorage.getItem("usuarioCash"));
  const idEmpresa = (usuarioCash && Array.isArray(usuarioCash) && usuarioCash.length > 0)
    ? usuarioCash[0]["id"]
    : null;

  const [isSoundEnabled, setIsSoundEnabled] = useState(enableSound);
  const soundEnabledRef = useRef(isSoundEnabled);

  const [isShaking, setIsShaking] = useState({
    'starRating': false,
    'flow': false,
    'limSimultaneo': false
  });

  const [isFirstLoad, setIsFirstLoad] = useState({
    'category': true,
    'categoryLimSim': true,
    'flow': true,
    'limSimultaneo': true
  });

  const [notification, setNotification] = useState({
    'starRating': 0,
    'flow': 0,
    'limSimultaneo': 0
  });

  const star = {
    "Unknown": 0,
    "Comum": 1,
    "Premium": 2,
    "Premium Plus": 3,
    "Premium Plus Plus": 4,
  };

  const flowData = [
    { name: "normal", label: 'Normal', color: "success" },
    { name: "atencao", label: "Atenção", color: "warning" },
    { name: "critico", label: "Crítico", color: "error" },
    { name: "desconhecido", label: "Desconhecido", color: 'primary' }
  ];

  const [playNotification] = useSound('./notification.wav');
  const [playAtivar] = useSound('./ATIVAR.mp3');
  const [playDesativar] = useSound('./DESATIVAR.mp3');
  const [isExpandedMenu, setIsExpandedMenu] = useState(() =>
    window.matchMedia("(min-width: 1200px)").matches);
  const [isExpandedAlert, setIsExpandedAlert] = useState(() =>
    window.matchMedia("(min-width: 1200px)").matches
  );

  const toggleExpandAlert = () => setIsExpandedAlert(!isExpandedAlert);
  const toggleExpandMenu = () => setIsExpandedMenu(!isExpandedMenu);

  
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1200px)");

    const handleScreenResize = (event) => {
      setIsExpandedAlert(event.matches);
    };

    mediaQuery.addEventListener("change", handleScreenResize);

    return () => mediaQuery.removeEventListener("change", handleScreenResize);
  }, []);

  useEffect(() => {
    if (isShaking) {
      const timer = setTimeout(() => {
        setIsShaking(prev => ({
          'starRating': false,
          'flow': false,
          'limSimultaneo': false
        }));
      }, 5000);

      return () => clearTimeout(timer);
    };
  }, [isShaking]);

  useEffect(() => {
    soundEnabledRef.current = isSoundEnabled;
    localStorage.setItem('soundPreference', JSON.stringify(isSoundEnabled));
  }, [isSoundEnabled]);

  const idsRecarga = useQuery(['recarga', localStorage.getItem("usuarioCash")], verificaEmpresaRecarga, {refetchInterval: false});

  const { data: category, refetch: refetchCategory } = useQuery(
    ['category', idEmpresa],
    () => getCategory(idEmpresa),
    {
      enabled: !!idEmpresa,
      refetchInterval: 30000,
      onError: () => console.error('Error fetching category')
    }
  );

  const { data: limSimultaneo, refetch: refetchLimSimultaneo } = useQuery(
    ['limSimultaneo', idEmpresa],
    () => getLimiteSimultaneo(idEmpresa),
    {
      enabled: !!idEmpresa,
      refetchInterval: 30000,
    }
  );

  const { data: categoryLimSim, refetch: refetchLimSimultaneoByCategory } = useQuery(
    ['categoryLimSim', idEmpresa],
    () => getCategoryLimSim(idEmpresa),
    {
      enabled: !!idEmpresa,
      refetchInterval: 30000,
    }
  );

  const { data: flow, refetch: refetchFlow } = useQuery(
    ['flow', idEmpresa],
    () => getFluxo(idEmpresa),
    {
      enabled: !!idEmpresa,
      refetchInterval: 30000,
    }
  );

  function onSoundClick() {
    if (!isSoundEnabled) {
      playNotification();
    }
    setIsSoundEnabled(!isSoundEnabled);
  }

  function onNotificationClick(field, value) {
    setNotification(prev => ({ ...prev, [field]: value }))
  }

  useEffect(() => {
    if (!limSimultaneo) return;

    if (isFirstLoad['limSimultaneo'] || isFirstLoad['category']) {
      setIsFirstLoad(prev => ({ ...prev, 'limSimultaneo': false }));
      return;
    }

    if (limSimultaneo === 'bloqueio' && soundEnabledRef.current) {
      playAtivar();
    } else if (soundEnabledRef.current) {
      playDesativar();
    }

    setIsShaking(prev => ({ ...prev, 'limSimultaneo': true }));
    onNotificationClick('limSimultaneo', 1)

  }, [limSimultaneo]);

  useEffect(() => {
    if (!category) return;

    if (isFirstLoad['category'] || isFirstLoad['categoryLimSim']) {
      setIsFirstLoad(prev => ({ ...prev, 'category': false }));
      return;
    }

    if (soundEnabledRef.current && category !== 'undefined') {
      playAtivar();
    }

    setIsShaking(prev => ({ ...prev, 'starRating': true }));
    onNotificationClick('starRating', 1)


  }, [category]);

  useEffect(() => {
    if (!categoryLimSim || categoryLimSim === 'infinito') return;

    if (isFirstLoad['category'] || isFirstLoad['categoryLimSim']) {
      setIsFirstLoad(prev => ({ ...prev, 'categoryLimSim': false }));
      return;
    }

    if (soundEnabledRef.current && 'categoryLimSim' !== '0') {
      playNotification();
    }
    setIsShaking(prev => ({ ...prev, 'starRating': true }));
    onNotificationClick('starRating', 1)


  }, [categoryLimSim]);

  useEffect(() => {
    if (!flow) return;

    if (isFirstLoad['flow']) {
      setIsFirstLoad(prev => ({ ...prev, 'flow': false }));
      return;
    }

    if (soundEnabledRef.current && flow !== 'desconhecido') {
      playAtivar();
    }

    setIsShaking(prev => ({ ...prev, 'flow': true }));
    onNotificationClick('flow', 1)

  }, [flow]);

  const NotificationMenu =()=>{
    return <div id='alert'>
      {/* Star Rating */}
      <FourStarRating
        className='chip'
        rating={star[category]}
        isShaking={isShaking['starRating']}
        badge={notification['starRating']}
        onClick={() => onNotificationClick('starRating', 0)}
      />

      {/* Flow Badge */}
      {flowData.filter(item => item.name === flow)[0] && (
        <Badge
          className='notification'
          badgeContent={notification['flow']}
          variant='dot'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            badge: {
              sx: {
                transform: 'translateY(7px)',
                backgroundColor: cores.amarelo,
              },
            },
          }}
        >
          <Chip
            label={"Fluxo: " + flowData.filter(item => item.name === flow)[0]?.label}
            variant='outlined'
            size='medium'
            className={`chip ${isShaking['flow'] ? 'shake' : ''}`}
            color={flowData.filter(item => item.name === flow)[0]?.color}
            clickable
            onClick={() => onNotificationClick('flow', 0)}
          />
        </Badge>
      )}

      {/* LimSimultaneo Badge */}
      <Badge
        className='notification'
        badgeContent={notification['limSimultaneo']}
        variant='dot'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          badge: {
            sx: {
              transform: 'translateY(7px)',
              backgroundColor: cores.amarelo,
            },
          },
        }}
      >
        <Chip
          label={<span>Lim. simult. <strong>{limSimultaneo === 'bloqueio' ? 'ON' : 'OFF'}</strong></span>}
          className={`chip ${isShaking['limSimultaneo'] ? 'shake' : ''}`}
          color={limSimultaneo === 'bloqueio' ? "success" : "error"}
          variant='outlined'
          clickable
          onClick={() => onNotificationClick('limSimultaneo', 0)}
        />
      </Badge>
    </div>
};


return (
  <div className="navbar" style={{ width: prop.width }}>
    <div className="wrapper">
      <div className="logo">
        <Link to="/home" style={{ textDecoration: "none" }}>
          <img className='logo' src={logos} alt="carregando" width={140} height={70} background={"white"} />
        </Link>
      </div>

      {prop.usuarioAtivo === true &&
        <div className='row'>

          {isExpandedAlert && <NotificationMenu />}

          {isExpandedMenu && <MenuLateral idsRecarga={idsRecarga}/>}

          {/*Button on/off sound */}
          <button
            onClick={onSoundClick}
            className='menu-buttons'>
            <div className='row bell-icon'>
              {isSoundEnabled ? <VolumeUpOutlinedIcon /> : <VolumeOffOutlinedIcon />}
            </div>
          </button>

          {/*Button toggle expand alerts */}
          <button
            onClick={toggleExpandAlert}
            id='toggleExpand'
            className='menu-buttons'>
            <div className='row bell-icon'>
              <Badge
                variant='standard'
                color='secondary'
                badgeContent={notification['flow'] + notification['limSimultaneo'] + notification['starRating']}>
                <NotificationsIcon fontSize="medium" color='white' />
              </Badge>
            </div>
          </button>

          {/*Button toggle expand menu */}
          <button
            onClick={toggleExpandMenu}
            id='toggleExpand'
            className='menu-buttons'>
            <div className='row bell-icon'>
              <Menu fontSize="medium" color='white' />
            </div>
          </button>


        </div>
      }
    </div>
  </div>
);
};

export default NavBar;
