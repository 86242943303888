import axios from 'axios';

/* SUMMARY
    procuraEmpresa: Searches for a company by its ID
    procuraFiliais: Searches for all branches linked to a company
    registraFilial: Registers a branch under a company
    deletaFiliais: Deletes branches from a company
    createCellphone: Creates and registers a cellphone for a company
*/

const baseURL = '/gestaoLojas';

const gestaoLojasApi = axios.create({
  baseURL: baseURL,
});

export const procuraEmpresa = async (id) => {
  const res = await gestaoLojasApi.get('/procuraEmpresa', { params: { id } });
  return res.data;
};

export const procuraFiliais = async (id) => {
  const res = await gestaoLojasApi.get('/procuraFiliais', { params: { id } });
  return res;
};

export const procuraTelefones = async (id) => {
  const res = await gestaoLojasApi.get('/procuraTelefones', { params: { id } });
  return res;
};

export const registraFilial = async (idMatriz, idFilial) => {
  const res = await gestaoLojasApi.get('/registraFilial', {
    params: { idMatriz, idFilial },
  });
  return res;
};

export const deletaFiliais = async (idMatriz, arrayIDFilial) => {
  const res = await gestaoLojasApi.get('/deletaFiliais', {
    params: { idMatriz, arrayIDFilial },
  });
  return res;
};

export const createCellphone = async (idMatriz, cellphone) => {
  const res = await gestaoLojasApi.post('/createCellphone', {
    params: { idMatriz, ...cellphone },
  });
  return res;
};

export const deleteCellphone = async (idMatriz, idCellphone) => {
  const res = await gestaoLojasApi.delete('/cellphone', {
    params: { idMatriz, idCellphone },
  });

  return res;
};
