import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {useMutation } from 'react-query'
import { Oval } from  'react-loader-spinner'
import Input from "../../componentes/input/Input"
import Button from "../../componentes/button/Button"
import useAuth from "../../hooks/useAuth"
import PopUp from '../../componentes/popUp/PopUp'
import NavBar from "../../componentes/navbar/Navbar"

import logo from "./logo-id.png"

import { login } from '../../api/loginData'
import "./login.scss"


const Login = () => {
  const { signin } = useAuth();
  const navigate = useNavigate();

  const [ID, setID] = useState("");
  const [senha, setSenha] = useState("");
  const [loader, setLoader] = useState(false);
  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [conteudoPopUp, setConteudoPopUp] = useState("");

  const autenticacaoTaon=  useMutation(login, {
    onSuccess: (e) => {
      const res = signin(ID, senha, e);

      if (res) {
        setConteudoPopUp(res)
        setButtonPopUp(true)
        setLoader(false)
        return;
      }
  
      navigate("/home");
    },
    onError: (e) => {
      setConteudoPopUp(e)
      setLoader(false)
      setButtonPopUp(true)
    }
    
  });
  

  const lidarRecuperarSenha = () => {
    navigate('/recuperar-senha');
  };

  const lidarRecuperarID = () => {
    setConteudoPopUp("Para acessar o ID da sua empresa, basta consultar o relatório semanal de corridas que enviamos para o seu e-mail e também pelo WhatsApp. \n\n Em caso de dificuldade, sinta-se à vontade para entrar em contato diretamente com o suporte pelo número abaixo, onde poderá selecionar a opção 1. \n \nNúmero de suporte no WhatsApp: 0800 878 0353")
    setButtonPopUp(true)
  };

  const lidarLogin = () => {
    setLoader(true)
    
    if (!ID | !senha) {
      setConteudoPopUp("Preencha todos os campos")
      setLoader(false)
      setButtonPopUp(true)
      return;
    }

    var credinciais = {senha, ID}

    autenticacaoTaon.mutate(credinciais)
  };

  return (
    <div className="pagina">
        <div className="cabeca">
          <NavBar usuarioAtivo={false} />
        </div>
      <div className="corpo">
        <div className="tela-login">
          <div className="logo-id">
            <img src={logo} alt="testsss"  style={{ width: "30px", height:"30px" }}  />
          </div>
          <div className="entrada">
            <Input type="text" placeholder="Digite o ID da empresa" border={"1px solid #FAD40C"} width={"192px"}  value={ID} onChange={setID}/>
            <Input type="text" placeholder="Digite sua senha" border={"1px solid #FAD40C"} width={"192px"}  value={senha} onChange={setSenha}/>

            <Button width={"80px"} color={"#EEECEF"} height='auto' padding="10px 0px" background={'#6B4380'} border={'1px solid #FAD40C'} text={"Entrar"} onClick={() => {lidarLogin()}}/>

            {loader === true &&
              <div className='loader'>
                <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
              </div>
            }
          </div>

          <div className="lembrete">
            <div className="frases">
              <div className="textos-lembretes" onClick={() => {lidarRecuperarID()}}>Não sei meu ID</div>
              <div className="textos-lembretes" onClick={() => {lidarRecuperarSenha()}}>Não sei minha senha</div>
            </div>
          </div>

        </div>
      </div>

      <PopUp trigger={buttonPopUp} setTrigger={setButtonPopUp}>
        {
          <div>
            {conteudoPopUp}
          </div>
        }
      </PopUp>
    </div>
  );
};

export default Login;