import React, { useEffect, useState } from "react";
import {useMutation } from 'react-query';
import AdicionarTelefonePopUp from "./PopUpAddTelefone";
import PopUpConfirmacao from "../../../componentes/popUpVazio/PopUpVazio";
import PopUp from "../../../componentes/popUpVazio/PopUpVazio";
import Input from "../../../componentes/input/Input";
import Button from "../../../componentes/button/Button";
import CardTelefone from "../../../componentes/card/cardTelefone";
import Select from "../../../componentes/select/Select";
import { procuraTelefones, createCellphone, deleteCellphone } from '../../../api/gestaoLojas';
import { login } from '../../../api/loginData';
import useAuth from "../../../hooks/useAuth";
import PhoneIcon from '@mui/icons-material/Phone';


const phoneTypeOptions = [
  { value: 'Residencial', label: 'Residencial' },
  { value: 'Comercial', label: 'Comercial' },
  { value: 'Celular', label: 'Celular' },
];

const TelefonePopup = ({ companyId, refreshPage, setLoader }) => {
  const { verifyCredentials } = useAuth();
  const [buttonPopUpTelefone, setButtonPopUpTelefone] = useState(false);
  const [buttonNewTelefone, setButtonNewTelefone] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedPhoneId, setSelectedPhoneId] = useState(null);

  const [cellphones, setCellphones] = useState([{
    nomeAssociado: '',
    numero: '',
    tipo: ''
  }]);

  const [cellphone, setCellphone] = useState({
    nomeAssociado: '',
    numero: '',
    tipo: ''
  });

  const lidarTelefone = async() => {
    setLoader(true);
    if (!password || !cellphone) {
      alert("Preencha todos os campos.");
      setButtonPopUpTelefone(false);
      setLoader(false);
      return;
    }
    var credinciais = {senha: password, ID: companyId };
    await autenticacaoTaon.mutate(credinciais);

    setPassword("");
    setButtonPopUpTelefone(false);
    setLoader(false);
  };

  const handleDeleteClick = (phoneID) => {
    setSelectedPhoneId(phoneID);
    setConfirmationModal(true);
  };

  const confirmDelete = async () => {
    setLoader(true);
    try {
      await deleteCellphone(companyId, selectedPhoneId);
      refreshPage();
    } catch (error) {
      alert(`Erro ao deletar telefone: ${error.message}`);
    } finally {
      setLoader(false);
      setConfirmationModal(false);
    }
  };

  useEffect(() => {
    const fetchCellphones = async () => {
      try {
        setLoader(true);
        const res = await procuraTelefones(companyId);
        setCellphones(res.data);
      } catch (error) {
        console.error('Erro ao buscar telefones:', error);
      } finally {
        setLoader(false);
      }
    };

    fetchCellphones();
  }, []);

  const autenticacaoTaon = useMutation(login, {
    onSuccess: async (e) => {
      try {
        setLoader(true);
        
        const res = await verifyCredentials(companyId, password, e);
  
        if (res && res.status === 200) {
          const response = await createCellphone(companyId, cellphone);
  
          if (response.status === 200) {
            alert("Telefones adicionados com sucesso.");
            refreshPage();
          } else {
            console.error("Erro ao adicionar telefone:", response.data.message);
            alert(`Erro: ${response.data.message}`);
          }
        } else {
          console.error("Erro ao verificar credenciais:", res?.data);
          alert(`Erro ao verificar credenciais: ${res?.data}`);
        }
      } catch (error) {
        console.error("Erro ao adicionar telefone:", error);
        alert(`Erro ao adicionar telefone: ${error.message}`);
      } finally {
        setLoader(false);
      }
    },
  
    onError: (error) => {
      console.error("Erro ao autenticar:", error);
      alert(`Erro ao autenticar: ${error.message}`);
      setLoader(false);
    }
  });

  return (
    <div>
      <Button
        onClick={() => setButtonPopUpTelefone(true)}
        width={"auto"}
        padding={'5px'}
        color={"#ffffff"}
        background={"black"}
        border={"black"}
      >
        <PhoneIcon />
      </Button>

      <PopUp trigger={buttonPopUpTelefone} setTrigger={setButtonPopUpTelefone}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          { 
            (cellphones&&cellphones.length>0)?
            <div style={{textAlign: '-webkit-center'}}>
              <h1 className="title">Telefones Vinculados</h1>

              {cellphones&&cellphones.map((item)=>{
                return <CardTelefone numero={item.numero} nomeAssociado={item.nomeAssociado} tipo={item.tipo} onDelete={()=>handleDeleteClick(item._id)}/>
              })}
            </div>
            :
            <h1 className="title">Nenhum telefone vinculado</h1>
          }

          <Button
            text="Adicionar"
            width='auto'
            onClick={()=>setButtonNewTelefone(true)}
            background="#6B4380"
            border="1px solid #FAD40C"
            color="#EEECEF"
          />
          
        </div>
      </PopUp>

      <AdicionarTelefonePopUp
        trigger={buttonNewTelefone}
        setTrigger={setButtonNewTelefone}
        password={password}
        setPassword={setPassword}
        cellphone={cellphone}
        setCellphone={setCellphone}
        onSubmit={lidarTelefone}
      />

      <PopUpConfirmacao trigger={confirmationModal} setTrigger={setConfirmationModal}>
        <div style={{ padding: "15px" }}>
          <div style={{ color: "#5a595c", marginBottom: "10px" }}>
            {"Tem certeza que deseja excluir este telefone?"}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            < Button
              text='Não'
              width='120px'
              borderRadius='8px'
              border='black'
              background='#c84333'
              color='white'
              onClick={() => { setConfirmationModal(false)} }
            />

            < Button
              text='Sim'
              width='120px'
              borderRadius='8px'
              border='black'
              background='rgb(66, 175, 60)'
              color='white'
              onClick={ confirmDelete }
            />
          </div>

        </div>
      </PopUpConfirmacao>
    </div>
  );
};

export default TelefonePopup;
