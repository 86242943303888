import React, { useState } from "react";
import {useMutation } from 'react-query';
import PopUp from "../../../componentes/popUpVazio/PopUpVazio";
import Input from "../../../componentes/input/Input";
import Button from "../../../componentes/button/Button";
import CardFiliais from "../../../componentes/card/cardFiliais"
import DeleteIcon from '@mui/icons-material/Delete';
import { deletaFiliais } from '../../../api/gestaoLojas';
import { login } from '../../../api/loginData';
import useAuth from "../../../hooks/useAuth";

const DeletePopup = ({ companyId, toEdit, data, refreshPage, setLoader }) => {
  const { verifyCredentials } = useAuth();
  const [buttonPopUpDelete, setButtonPopUpDelete] = useState(false);
  const [password, setPassword] = useState("");


  const lidarDelete = async() => {
    setLoader(true);
    if (!password) {
      alert("Preencha todos os campos.");
      setButtonPopUpDelete(false);
      setLoader(false);
      return;
    }
    var credinciais = {senha: password, ID: companyId };
    await autenticacaoTaon.mutate(credinciais);

    setPassword("");
    setButtonPopUpDelete(false);
    setLoader(false);
  };


  const autenticacaoTaon = useMutation(login, {
    onSuccess: async (e) => {
      try {
        setLoader(true);
        
        const res = verifyCredentials(companyId, password, e);
  
        if (res && res.status === 200) {
          const response = await deletaFiliais(companyId, toEdit);
  
          if (response.status === 200) {
            alert("Filiais removidas com sucesso.");
            refreshPage();
          } else {
            console.error("Erro ao remover filial:", response.data.message);
            alert(`Erro: ${response.data.message}`);
          }
        } else {
          console.error("Erro ao verificar credenciais:", res?.data);
          alert(`Erro ao verificar credenciais: ${res?.data}`);
        }
      } catch (error) {
        console.error("Erro ao remover filial:", error);
        alert(`Erro ao remover filial: ${error.message}`);
      } finally {
        setLoader(false);
      }
    },
  
    onError: (error) => {
      console.error("Erro ao autenticar:", error);
      alert(`Erro ao autenticar: ${error.message}`);
      setLoader(false);
    }
  });
  
  return (
    <div>
      <Button
        onClick={() => setButtonPopUpDelete(true)}
        width={"auto"}
        padding={'5px'}
        color={"#ffffff"}
        background={"black"}
        border={"black"}
      >
        <DeleteIcon />
      </Button>

      <PopUp trigger={buttonPopUpDelete} setTrigger={setButtonPopUpDelete}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h1 className="title">Remover Filial</h1>
            <p>Você deseja remover as seguintes empresas da tela de gestão?</p><br />
            <ul style={{padding: '0px'}}>
              {
                toEdit.map((idFilial)=>{
                  const item = data.find(item => item._id === idFilial);
                  return <CardFiliais id={item.id_empresa} nome_empresa={item.nome_empresa} />
                }  
              )}
            </ul>

            <br />

            <Input
              label="Password"
              value={password}
              onChange={setPassword}
              placeholder="Sua Senha"
              margin='auto'
            />

            <br />

            <Button 
              width={"80px"} 
              color={"#EEECEF"} 
              height='auto' 
              margin='auto' 
              padding="10px 0px" 
              background={'#6B4380'} 
              border={'1px solid #FAD40C'} 
              text={"Remover"} 
              disabled={password.length>0?false:true}
              onClick={() => {lidarDelete()}}/>
      </div>
      </PopUp>
    </div>
  );
};

export default DeletePopup;
