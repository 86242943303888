import Layout from '../../componentes/layout';
import GestaoLojas from './GestaoLojas';

const PaginaGestaoLojas = () => {

    return <>
      <Layout>
        <GestaoLojas />
      </Layout>
  
    </>
  
  }
  
export default PaginaGestaoLojas;
