import React, { useEffect,useState, useMemo} from 'react';
import Pagination from '../pagination/Pagination';
import './table.scss';

const Table = props => {
    const {
      onPageChange,
      totalCount,
      countSelected,
      currentPage,
      pageSize,
      head,
      data,
    } = props;

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, data]);

    useEffect(() => {
        var lista = document.querySelectorAll("input");

        for ( var i = 0 ; i < lista.length ; i++ ){
            lista[i].checked = false
        }

       var updatedCheckedState = checkedState.map((intem, indice)=>{
            return ""
        })
        setCheckedState(updatedCheckedState)

    }, [currentPage]);

    const [checkedState, setCheckedState] = useState(new Array(20).fill("") );  
    const handleSelectAll = (status) => {

        let updatedCheckedState
        if(status){
            var lista = document.querySelectorAll("input");
    
            for ( var i = 0 ; i < lista.length ; i++ ){
                lista[i].checked = true
            }
            
            updatedCheckedState = currentTableData.map((intem, indice)=>{
                if(currentTableData[indice] !== undefined){
                    return currentTableData[indice]["_id"]
                }else{
                    return"i"
                }
            })
    
        }else{
            var lista = document.querySelectorAll("input");
    
            for ( var i = 0 ; i < lista.length ; i++ ){
                lista[i].checked = false
            }

            updatedCheckedState = checkedState.map((intem, indice)=>{
                return ""
            })
        }

        setCheckedState(updatedCheckedState);
        return updatedCheckedState
    }

    const handleChange = (position, status, value) => {
                
        var updatedCheckedState = checkedState.map((item, index) => 
        {
           if(index == position && status){
                return value
                
            }
            if(index == position && !status){
                return ''
                
            }
            return item
        });

        setCheckedState(updatedCheckedState);

        return updatedCheckedState
    };

    return (
        <div>
            <table className="taable" style={{width: '-webkit-fill-available'}}>
                <thead className="head">
                    <tr className="th">
                        <th className="th">
                            <input         
                                className='box' 
                                type="checkbox"
                                onChange={(e) =>props.onChangeCheckBox(handleSelectAll(e.target.checked))}
                            />
                        </th>
                            {
                                head.map((key) => 
                                (<th className="th" key={key.value}>{key.label}</th>))
                            }
                    </tr>
                </thead>
                <tbody>
                    {currentTableData.length > 0 &&
                    currentTableData.map((row, indexx) => {
                        const isEvenRow = indexx % 2 === 0;

                        return (
                            <tr key={indexx} className={isEvenRow ? 'even-row' : 'odd-row'}>
                                <td className="td">
                                    <input
                                        className='box'
                                        type="checkbox"
                                        id={`custom-checkbox-${indexx}`}
                                        name={row['_id']}
                                        value={row['_id']}
                                        onChange={(e) => props.onChangeCheckBox(handleChange(indexx, e.target.checked, e.target.name))}
                                    />
                                </td>
                                <td className="td">{row.nome_empresa}</td>
                                <td className="td">{row.agendadas}</td>
                                <td className="td">{row.emAndamento}</td>
                                <td className="td">{row.limSim === 'infinito' ? <>&infin;</> : row.limSim} </td>
                            </tr>
                        );
                    })}
                </tbody>

            </table>
            <div style={{justifyContent: 'center', display: 'flex'}}>
                { countSelected ?
                    <p className='countSelected'>
                        Selecionado: {countSelected}
                    </p>
                    :null
                }
                <Pagination  onPageChange={onPageChange} totalCount={totalCount} siblingCount={1} currentPage={currentPage} pageSize={pageSize}/>
            </div>
        </div>
    )
}

export default Table

