import * as React from 'react';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Typography from '@mui/material/Typography';

import { CardActionArea } from '@mui/material';

export default function CardFiliais({id, nome_empresa, onClick}) {
  return (
    <Card sx={{ margin: '10px  0px 10px 10px', width: '350px', height: 'auto', overflowY: 'hidden'}}>
      <CardActionArea onClick={onClick} sx={{display: 'inline !important'}}>
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", height: 'auto' }}>
            
            <PlaceOutlinedIcon style={{ marginRight: "5px" }} />
            <Typography gutterBottom variant="p4" component="div" style={{height: 'auto'}}>
              <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px" }}>{nome_empresa}</p>
            </Typography>
            <Chip label={`ID: ${id}`} variant="outlined" color="primary" style={{ height: "auto", marginLeft: "auto" }}/>  
          </div>
        
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
  
