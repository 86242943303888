import React, { useState } from "react";
import {useMutation } from 'react-query';
import PopUp from "../../../componentes/popUpVazio/PopUpVazio";
import Input from "../../../componentes/input/Input";
import Button from "../../../componentes/button/Button";
import AddIcon from '@mui/icons-material/Add';
import { registraFilial } from '../../../api/gestaoLojas';
import { login } from '../../../api/loginData';
import useAuth from "../../../hooks/useAuth";


const AddPopup = ({ idMatriz, refreshPage, setLoader }) => {
  const { verifyCredentials } = useAuth();

  const [buttonPopUpAdd, setButtonPopUpAdd] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [password, setPassword] = useState("");


  const lidarLogin = async() => {
    setLoader(true);
    if (!companyId || !password) {
      alert("Preencha todos os campos.");
      setButtonPopUpAdd(false);
      setLoader(false);
      return;
    }
    var credinciais = {senha: password, ID: companyId };
    await autenticacaoTaon.mutate(credinciais);

    setCompanyId("");
    setPassword("");

    setButtonPopUpAdd(false);
    setLoader(false);
  };

  const autenticacaoTaon = useMutation(login, {
    onSuccess: async (e) => {
      try {
        setLoader(true);
        
        const res = verifyCredentials(companyId, password, e);
  
        if (res && res.status === 200) {
          const response = await registraFilial(idMatriz, companyId);
  
          if (response.status === 200) {
            console.log("Filial adicionada com sucesso:", response.data.message);
            alert("Filial adicionada com sucesso.");
            refreshPage();
          } else {
            console.error("Erro ao adicionar filial:", response.data.message?.message);
            alert(`Erro: ${response.data.message}`);
          }
        } else {
          console.error("Erro ao verificar credenciais:", res?.data);
          alert(`Erro ao verificar credenciais: ${res?.data}`);
        }
      } catch (error) {
        console.error("Erro ao adicionar filial:", error);
        alert(`Erro ao adicionar filial: ${error.message}`);
      } finally {
        setLoader(false);
      }
    },
  
    onError: (error) => {
      console.error("Erro ao autenticar:", error);
      alert(`Erro ao autenticar: ${error.message}`);
      setLoader(false);
    }
  });  

  return (
    <div>
      <Button
        onClick={() => setButtonPopUpAdd(true)}
        width={"auto"}
        padding={'5px'}
        color={"#ffffff"}
        background={"black"}
        border={"black"}
      >
        <AddIcon />
      </Button>

      <PopUp trigger={buttonPopUpAdd} setTrigger={setButtonPopUpAdd}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h1 className="title">Adicionar Empresa</h1>
            <Input
              label="ID Empresa"
              value={companyId}
              onChange={setCompanyId}
              placeholder="ID Empresa"
            />
            
            <Input
              label="Password"
              value={password}
              onChange={setPassword}
              placeholder="Senha da filial"
            />

            <Button 
              width={"80px"} 
              color={"#EEECEF"} 
              height='auto' 
              margin='auto' 
              padding="10px 0px" 
              background={'#6B4380'} 
              border={'1px solid #FAD40C'} 
              text={"Adicionar"} 
              onClick={() => {lidarLogin()}}/>
      </div>
      </PopUp>
    </div>
  );
};

export default AddPopup;
