import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import useAuth from '../../hooks/useAuth'

import PopUpCredito from "../popUpVazio/popUpCreditos/popUpCredtos";

import "./menuLateral.scss";

const MenuLateral = (props) => {
    const { user, signout } = useAuth()
    const navigate = useNavigate();
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [popUpCreditos, setPopUpCreditos] = useState(false);

    const menuItems = [
        // { path: "/home", icon: "local_shipping", label: "Grandes Encomendas", deactivate: false },
        // { path: "/home", icon: "payments", label: "Gestão Financeira", deactivate: true },
        { path: "/retornos", icon: "rotate_left", label: "Controle de Retornos", deactivate: false },
        { path: "/taonPay", icon: "store", label: "Convênio Taon", deactivate: false },
        ...(process.env.REACT_APP_USUARIOS_PREMIUM.includes(user.ID)
        ? [{ path: "/gestaoLojas", icon: "speed", label: "Gestão de Lojas", deactivate: false }]
        : []),
    ];

    const toggleMenu = () => setIsExpanded(!isExpanded);


    return (
        <div className={`tela-lateral ${isExpanded ? "expanded" : "collapsed"}`}>
            
            <div className='itens-menu'>
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`bloco-clicavel ${location.pathname === item.path ? "active" : ""}`}
                        onClick={() => !item.deactivate && navigate(item.path)}
                    >
                        <div className="bloco-icone">
                            <Icon>{item.icon}</Icon>
                        </div>
                        {isExpanded && (
                            <div className="titulo">
                                {item.label}
                            </div>
                        )}
                    </div>
                ))}

                <div
                    className={`bloco-clicavel`}
                    onClick={() => [signout(), navigate("/login")]}
                    >
                        <div className="bloco-icone">
                        <Icon className="icon-update">logout_icon</Icon>
                        </div>
                        {isExpanded && (
                            <div className="titulo">
                                Logout
                            </div>
                        )}
                </div>
            </div>

            <IconButton className="toggle-button" onClick={toggleMenu}>
                {isExpanded ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>

            {props.idsRecarga.data === true &&
                <div className='itens-recarga'>
                    <div
                            className={`bloco-recarga`}
                            onClick={() => {setPopUpCreditos(true)}}
                            >
                                <div className="bloco-icone">
                                <Icon className="icon-update">add</Icon>
                                </div>
                                {isExpanded && (
                                    <div className="titulo">
                                        Recarregar Créditos 
                                    </div>
                                )}
                    </div>
                </div>
            }

            <PopUpCredito trigger={popUpCreditos} setTrigger={setPopUpCreditos}></PopUpCredito>

        </div>
    );
};

export default MenuLateral;
