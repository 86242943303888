import React from 'react';
import Badge from '@mui/material/Badge';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import cores  from '../cores/Cores'

const FourStarRating = ({ rating, badge, isShaking, onClick, ...props }) => {
  return (
    [1, 2, 3, 4].includes(rating) ?
      <Badge
        badgeContent={badge}
        className='notification'
        variant='dot'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          badge: {
            sx: {
              transform: 'translateY(7px)',
              background: cores.amarelo
            },
          },
        }}
      >
        <div 
          className={isShaking ? 'shake' : ''} 
          style={{ 
            display: 'flex', 
            borderWidth: '1px', 
            borderStyle: 'solid', 
            borderColor: 'gold', 
            borderRadius: '15px', 
            padding: '3px'          
           }} 
          onClick={() => onClick()} 
          {...props}
        >
          {[1, 2, 3, 4].map((value) => (
            <div key={value}>
              {value <= rating ? (
                <StarIcon style={{ color: 'gold', height: '13px', margin: '0px', padding: '0px' }} />
              ) : (
                <StarBorderIcon style={{ color: 'gold', height: '13px', margin: '0px', padding: '0px' }} />
              )}
            </div>
          ))}
        </div>
      </Badge>

      : null
  );
};

export default FourStarRating;

