import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useOutletContext } from "react-router-dom";
import { Oval } from 'react-loader-spinner';
import SpeedIcon from '@mui/icons-material/Speed';
import Button from '../../componentes/button/Button';
import Table from './componentes/table/Table';

import AddPopup from './componentes/PopUpAddFilial';
import TrashPopUp from './componentes/PopUpTrash';
import TelPopup from './componentes/PopUpTelefone';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { procuraFiliais } from '../../api/gestaoLojas';

const GestaoLojas = () => {

  const baseURL = '/gestaoLojas'
  const gestaoLojasApi = axios.create({
    baseURL: baseURL,
  })

  let PageSize = 20;

  const cabecaTabela = [
    { value: 'nome_empresa', label: 'Empresa' },
    { value: 'agendadas', label: 'Agendadas' },
    { value: 'emAndamento', label: 'Em Andamento' },
    { value: 'limSim', label: 'Limite Simultâneo' },
  ]

  const idEmpresa = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]
  const [data, setData] = useState([]);
  const [listCheckBox, setListCheckBox] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [loader, setLoader] = useState(false);

  const [primeiroRender, setprimeiroRender] = useState(true);

  const [atualizaCobranca, setAtualizaCobranca] = useState(false);

  const [dataConsulta, setDataConsulta] = useState('');

  const { data: dataRefresh, refetch: refetchData } = useQuery(
    ['procuraFiliais', idEmpresa],
    () => procuraFiliais(idEmpresa),
    {
      enabled: !!idEmpresa,
      refetchInterval: 90000,
    }
  );

  useEffect(() => {
    if (!dataRefresh) return;

    setData(dataRefresh.data);

  }, [dataRefresh]);


  const fetchData = async () => {
    setLoader(true)

    const response = await procuraFiliais(idEmpresa);

    const ultimaAtualizacao = await gestaoLojasApi.get('/procuraConsulta')

    try {
      const agora = new Date(ultimaAtualizacao.data[0]["consultas"]["financeira"]);
      const opcoesLocalizacao = {
        timeZone: 'America/Sao_Paulo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      };

      const dataHoraBrasileira = agora.toLocaleString('pt-BR', opcoesLocalizacao);
      setDataConsulta(dataHoraBrasileira)
    } catch (error) {

    }
    setprimeiroRender(false)
    setData(response.data)
    setLoader(false)

  }

  const refreshPage = async () => {
    window.location.reload(false);
  }

  const handleRefresh = async () => {
    try {
      setLoader(true);
      await refetchData();
      setLoader(false);
    } catch (error) {
      console.error("Falha ao recarregar dados: ", error);
    }
  };

  useEffect(() => {

    if (atualizaCobranca == true) {
      fetchData();
      setAtualizaCobranca(false)
    }

  }, [atualizaCobranca, setAtualizaCobranca])

  useEffect(() => {
    if (primeiroRender) {
      fetchData();
    }

  }, [data, setData])

  return (
    <div className="main">
      <div className="tela-principal-retornos">
        <div className="titulo-grandes-entregas">
          <div className="texto-titulo-Grandes-encomendas">
            <SpeedIcon style={{ width: '30px', marginRight: '8px', height: 'auto', color: '#6B4380' }} />
            Gestão de Lojas
          </div>
        </div>

        <div className="container-financeira">
          <div className="container">
            <div className="components">
              {loader == true &&
                <div className='loader'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                </div>
              }

            </div>
          </div>

          <div className="divTable">
            {
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: "13px", marginLeft: "15px", color: "#aaa6a6" }}>
                  <div>
                    {dataConsulta !== '' && "Atualizada em: " + dataConsulta}
                  </div>

                  <div className='buttons'>
                    {listCheckBox.filter(item=>item).length > 0 && (
                      <TrashPopUp
                        companyId={idEmpresa}
                        data={data}
                        setData={setData}
                        toEdit={(listCheckBox.filter((item) => item))}
                        refreshPage={refreshPage}
                        setLoader={setLoader}
                      />
                    )}

                    <AddPopup
                      idMatriz={idEmpresa}
                      refreshPage={refreshPage}
                      setLoader={setLoader}
                    />

                    <TelPopup
                      companyId={idEmpresa}
                      refreshPage={refreshPage}
                      setLoader={setLoader}
                    />

                    <Button
                      onClick={handleRefresh}
                      width={"auto"}
                      color={"#ffffff"}
                      background={"black"}
                      border={"#c84333"}
                    >
                      <AutorenewIcon />
                    </Button>

                  </div>
                </div>

                {
                  <Table
                    name={"table"}
                    value={"value"}
                    data={data}
                    head={cabecaTabela}
                    onChangeCheckBox={setListCheckBox}
                    onPageChange={page => setCurrentPage(page)}
                    totalCount={data.length}
                    countSelected={(listCheckBox.filter((item) => item)).length}
                    currentPage={currentPage}
                    pageSize={PageSize}
                  />
                }
              </div>
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default GestaoLojas;