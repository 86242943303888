import React, { useState } from 'react';
import PopUp from "../../../componentes/popUpVazio/PopUpVazio";
import Input from "../../../componentes/input/Input";
import Select from "../../../componentes/select/Select";
import Button from "../../../componentes/button/Button";

const phoneTypeOptions = [
  { value: 'Residencial', label: 'Residencial' },
  { value: 'Comercial', label: 'Comercial' },
  { value: 'Celular', label: 'Celular' },
];

const AdicionarTelefonePopUp = ({ trigger, setTrigger, cellphone, setCellphone, password, setPassword, onSubmit }) => {

  const handleInputChange = (name, value) => {
    setCellphone((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (value) => {
    setCellphone((prevState) => ({
      ...prevState,
      tipo: value,
    }));
  };

  return (
    <PopUp trigger={trigger} setTrigger={setTrigger}>
      <div style={{ textAlign: 'center' }}>
        <h1 className="title">Adicionar Novo Telefone</h1>
        
        <Input
          label="Nome Responsável"
          name="nomeAssociado"
          value={cellphone.nomeAssociado}
          onChange={(e) => handleInputChange('nomeAssociado', e)}
          placeholder="Nome do responsável"
        />
        <br />

        <Input
          label="Número Telefone"
          name="numero"
          value={cellphone.numero}
          onChange={(e) => handleInputChange('numero', e)}
          placeholder="(+55) 99 99999-9999"
        />
        <br />

        <Select
          titulo="Selecione o tipo de telefone"
          options={phoneTypeOptions}
          onChange={handleSelectChange}
        />
        <br />

        <Input
          label="Senha"
          value={password}
          onChange={setPassword}
          placeholder="Sua Senha"
        />
        <br />

        <Button
          text="Adicionar"
          width='auto'
          onClick={onSubmit}
          disabled={!password}
          background="#6B4380"
          border="1px solid #FAD40C"
          color="#EEECEF"
        />
      </div>
    </PopUp>
  );
};

export default AdicionarTelefonePopUp;
