import React from "react";
import { createContext, useEffect, useState, useContext } from "react";
import {useMutation } from 'react-query'

import {login} from '../api/loginData'

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();

  const autenticacaoTaon=  useMutation(login, {
    onSuccess: (e) => {
      const usuarioSalvooo = JSON.parse(localStorage.getItem("usuarioCash"));

      //desencriptar
      var CryptoJS = require("crypto-js");
      var bytes  = CryptoJS.AES.decrypt(usuarioSalvooo[0].senha, 'TAON-TI qX!U3N#9fwk%gPrm');
      var originalTexto = bytes.toString(CryptoJS.enc.Utf8);

      if(e.data.numero_contrato === originalTexto){
        setUser({ ID:e.data.id, senhaEncriptada: usuarioSalvooo[0].senha });

      }else{
        setUser(null)
      }
    },
    onError: (e) => {
      alert(e)
    }
    
  });

  useEffect(() => {
    const usuarioSalvooo = JSON.parse(localStorage.getItem("usuarioCash"));

    if(usuarioSalvooo == null){

    }else{
      var CryptoJS = require("crypto-js");
      var bytes  = CryptoJS.AES.decrypt(usuarioSalvooo[0].senha, 'TAON-TI qX!U3N#9fwk%gPrm');
      var originalText = bytes.toString(CryptoJS.enc.Utf8);

      var usuario =  usuarioSalvooo[0].id

      var credinciais = {senha: originalText, ID: usuario}

      autenticacaoTaon.mutate(credinciais)
    }
  }, []);


  const signin = (ID, senha, credenciais) => {
    var CryptoJS = require("crypto-js");

    // Encriptar
    var senhaEncriptada = CryptoJS.AES.encrypt(senha, 'TAON-TI qX!U3N#9fwk%gPrm').toString();

    var senha_machine = credenciais.data.numero_contrato

    if(senha === senha_machine){

      const usuarioSalvo = JSON.parse(localStorage.getItem("usuarioCash"));
      const verifica = usuarioSalvo?.filter((user) => user.id === ID)

      if (verifica?.length) {
        setUser({ ID, senhaEncriptada });
        
        return;
      }else{
        var novoUsuario =[ {id:ID, senha:senhaEncriptada} ];
        localStorage.setItem("usuarioCash", JSON.stringify(novoUsuario));
        setUser({ ID, senhaEncriptada });
        return
     }

      
    }else{
      return "Senha incorreta";
    }
  };

  const verifyCredentials = (ID, senha, credenciais) => {    
    var senha_machine = credenciais.data.numero_contrato

    if(senha === senha_machine){
      return {status: 200, data: 'Credenciais válidas'}
    }else{
      return {status: 400, data: 'Senha incorreta'};
    }
  };


  const signout = () => {
    setUser(null);
    localStorage.removeItem("usuarioCash");
  };

  return (
    <AuthContext.Provider
      value={{ user, signed: !!user, signin, signout, verifyCredentials }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext)
}