import React from "react";
import { Routes, Route } from 'react-router-dom'
import Home from ".//paginas/home/Home"
import Mapa from "./paginas/map/map";
import GrandesEncomendas from ".//paginas/grandesEncomendas/GrandesEncomendas"
import Login from ".//paginas/login/Login"
import Recuperar from ".//paginas/recuperarSenha/Recuperar"
import useAuth from "../src/hooks/useAuth"
import Retornos from "./paginas/retornos/Retornos"
import PaginaFinanceiroMotoristas from "./paginas/financeiroMotoristas/FinanceiroMotoristas"
import PaginaGestaoLojas from "./paginas/gestaoLojas/PaginaGestaoLojas"
import Rastreio from "./paginas/rastreio/Rastreio"
import './index.scss'


function App() {
  const useAuthh = useAuth();

  const Privado = ({Item})=>{
    return useAuthh.signed === true ? <Item /> : <Login />
  }

  const isUserPremium = process.env.REACT_APP_USUARIOS_PREMIUM.includes(useAuthh?.user?.ID);  

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/recuperar-senha" element={<Recuperar />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Privado Item={Home} />} />
        <Route path="/grandesEncomendas" element={<Privado Item={GrandesEncomendas} />} />
        <Route path="/mapa" element={<Mapa />} />
        <Route path="/retornos" element={<Privado Item= { Retornos} /> }/>
        <Route path="/taonPay" element={<Privado Item={ PaginaFinanceiroMotoristas} />} />
        {isUserPremium && (
          <Route path="/gestaoLojas" element={<Privado Item={PaginaGestaoLojas} />} />
        )}
        <Route path="/rastreio/:id" element={<Rastreio /> }/>
      </Routes>
    </>
  );
}



export default App;