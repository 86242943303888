/* SUMÁRIO
  getCategory: Procura categoria (estrelas) por ID de empresa.
*/

import axios from 'axios'

const baseURL = '/client'

const clientApi = axios.create({
  baseURL: baseURL,
})

/**
 * Procura categoria por número de id da empresa.
 * @param {string} id - id da empresa.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getCategory = async (id) => { 
  const res = await clientApi.get('/category', {params: {id: id}})
  return res.data;
}

/**
 * Procura recarga por número de id.
 * @param {string} id - id da recarga.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const procuraPagamento = async (id) => { 
  var value = id.queryKey[0]._id
  
  const res = await clientApi.get('/procuraPagamento', {params: {id: value}})
  return res.data;
}

/**
 * Gera cobranca
 * @param {string} dic - dicionario.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const gerarCobranca = async (dic) => { 

  var value = dic
  const res = await clientApi.post('/gerarCobranca', value)
  return res;
}

/**
 * Cancela cobranca
 * @param {string} dic - dicionario.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const cancelarCobranca = async (dic) => { 
  var value = dic
  const res = await clientApi.get('/cancelarCobranca', {params: {value:value}})
  return res;
}


/**
 * Procura limite simultâneo da determinada categoria daquela empresa por número de id da empresa.
 * @param {string} id - id da empresa.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getCategoryLimSim = async (id) => { 
  const res = await clientApi.get('/categoryLimSim', {params: {id: id}})
  return res.data;
}

/**
 * Verifica acesso a recarga de credito por número de id da empresa.
 * @param {string} id - id da empresa.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const verificaEmpresaRecarga = async (id) => { 

  if(id.queryKey[1] === null){
    return false;
  }
  var value = JSON.parse(id.queryKey[1])[0]["id"]

  const res = await clientApi.get('/verificaEmpresaRecarga', {params: {id: value}})
  return res.data;
}

/**
 * Procura limite simultâneo por número de id da empresa.
 * @param {string} id - id da empresa.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getLimiteSimultaneo = async (id) => { 
  const res = await clientApi.get('/limiteSimultaneo', {params: {id: id}})
  return res.data;
}


/**
 * Procura fluxo da cidade por número de id da empresa.
 * @param {string} id - id da empresa.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getFluxo = async (id) => { 
  const res = await clientApi.get('/fluxo', {params: {id: id}})
  return res.data;
}