
import * as React from 'react';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function CardTelefone({numero, nomeAssociado, tipo, onClick, onDelete}) {
  return (
    <Card sx={{ margin: '10px  0px 10px 10px', width: '350px', height: 'auto', overflowY: 'hidden'}}>
      <CardActionArea onClick={onClick} sx={{display: 'inline !important'}}>
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", height: 'auto' }}>
            <PhoneIcon style={{ marginRight: "5px" }} />
            <Typography gutterBottom variant="p4" component="div" style={{height: 'auto'}}>
              <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px" }}>{numero}</p>
            </Typography>
            <Chip label={`Tipo: ${tipo}`} variant="outlined" color="primary" style={{ height: "auto", marginLeft: "auto" }}/>  
          </div>
        
          <div style={{ display: "flex", height: "auto" }}>
            <PersonIcon fontSize="small" style={{ marginRight: "5px" }}/>
            <Typography variant="body2" color="text.secondary">
              <p>{nomeAssociado}</p>
            </Typography>
            
            <IconButton aria-label="delete" onClick={onDelete} style={{ marginLeft: 'auto', padding: '0px'}}>
              <DeleteIcon />
            </IconButton>
          </div>

        </CardContent>
      </CardActionArea>
    </Card>
  );
}
  
