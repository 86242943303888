import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Oval } from 'react-loader-spinner'; 
import  {useMutation, useQuery} from 'react-query'
import { Icon, IconButton } from '@mui/material';

import Cores from '../../cores/Cores'
import Button from '../../button/Button'
import Input from "../../input/Input"
import PopUp from '../PopUp'

import load from "./load.gif";

import "./popUpCreditos.scss";

import { gerarCobranca, cancelarCobranca, procuraPagamento} from '../../../api/client';


const PopUpCredito = ({ trigger, setTrigger }) => {
    const [step, setStep] = useState(1); 
    const [loader, setLoader] = useState(false);
    const [valor, setValor] = useState('');
    const [valorTotal, setValorTotal] = useState("");
    const [taxa, setTaxa] = useState("1")
    const [confimaPagemento, setConfimaPagemento] = useState(false);
    const [tempoRestante, setTempoRestante] = useState(0);
    const [pagamento, setPagamento] = useState("");
    const [ativaConsulta, setAtivaConsulta] = useState(false);

    const qrCode = useQuery([{_id: pagamento._id}], procuraPagamento, {refetchInterval: 1000,  enabled: ativaConsulta});


    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const initialStep = () => {
        setStep(1);
    };

    const slideVariants = {
        enter: { x: '100%', opacity: 0 },
        center: { x: 0, opacity: 1 },
        exit: { x: '-100%', opacity: 0 },
    };

    const lidarCopiarQrCode = async(event) =>{
    
        await navigator.clipboard.writeText(pagamento.emv_payload);
    }

    const lidarComConfimacao = () => {
        setConfimaPagemento(false)
        setLoader(true)
         if(valor  <= 0 && valor === ''){
             alert("Insira um valor maior que 0")
             setLoader(false)
             return
         }
         if(valor  > 20 && valor > 5000){
             alert("Insira um valor entre R$ 50,00 e R$ 5.000,00")
             setLoader(false)
             return
         }
         setValorTotal(parseFloat(valor) - ((parseFloat(valor)*9)/100))
 
         setLoader(false)
         nextStep()
    }

    const lidarPagamento = async() => {
        nextStep()   
        await delay(1200);   
        setLoader(true)
        console.log({id:JSON.parse(localStorage.getItem("usuarioCash")), valor:valor, formaPagamento: 'Qr Code'})
        geraCobranca.mutate({id:JSON.parse(localStorage.getItem("usuarioCash")), valor:valor, formaPagamento: 'Qr Code'})
    };

    const lidarFechar = () => {
        setValorTotal('')
        setValor('')
        setPagamento('')
        setTrigger(false)
        initialStep()
    };


    const formatarTempo = (segundos) => {
        const minutos = Math.floor(segundos / 60);
        const segundosRestantes = segundos % 60;
        return `${String(minutos).padStart(2, '0')}:${String(segundosRestantes).padStart(2, '0')}`;
    };

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const ativaTemporizador = async(event) =>{
        const tempoInicial = 120
        setTempoRestante(tempoInicial);
    
        const intervalo = setInterval(() => {
            setTempoRestante((prevTempo) => {
                if (prevTempo <= 1) {
                    clearInterval(intervalo);
                    if(confimaPagemento !== true){ 
                        cancelaCobranca.mutate({pagamento: event, id: JSON.parse(localStorage.getItem("usuarioCash"))});  
                        setPagamento('') 
                        setAtivaConsulta(false) 
                        lidarFechar()                
                    }
                    return 0;
                }
                return prevTempo - 1;
            });
        }, 1000);
    }

    const geraCobranca =  useMutation(gerarCobranca, {
        onSuccess: (e) => {
          setLoader(false)
          setValor("")

          if(e.status === 201){
            setPagamento(e.data)
            setAtivaConsulta(true)
            ativaTemporizador(e.data)
          }else{
            alert(e.data)
          }
        },
        onError: (e) => {
            setLoader(false)
            setValor("")
            alert("Erro ao gerar pagamento.")
        }
    });


    const cancelaCobranca =  useMutation(cancelarCobranca, {
        onSuccess: (e) => {

          if(e.status === 201){
            setPagamento('')

          }else{
          }
        },
        onError: (e) => {
        }
    });

    useEffect(() => {

        if (qrCode.data && qrCode.data.length > 0) {
            const status = qrCode.data[0].status;
            if (status === 'Finalizado') {
                setAtivaConsulta(false)
                setPagamento('');
                setConfimaPagemento(true);
            }
        }

    },[ qrCode.data])  


    return (
        <PopUp trigger={trigger} setTrigger={setTrigger} fechar={false}>
            <div style={{display:'flex', flexDirection: 'column'}}>
                <i className="material-icons" onClick={() => {lidarFechar()}}>close</i>
            
                <div className="popUp-credito">
                    <div className='div100'>
                    {loader && (
                        <AnimatePresence>
                            <motion.div
                                className="loader"
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.9 }}
                                transition={{ duration: 0.3 }}
                            >
                                <img className="icone" src={load} />
                            </motion.div>
                        </AnimatePresence>
                    )}


                    <AnimatePresence mode="wait">
                        {step === 1 && (
                            <motion.div
                                key="step1"
                                initial="enter"
                                animate="center"
                                exit="exit"
                                variants={slideVariants}
                                transition={{ duration: 0.5 }}
                                className="conteudo"
                            >
                                <div className="textenado">
                                <div className="texto-titulo-credito">Recarga Taon</div>
                                <div className="texto-bloco-credito">
                                    O valor mínimo para recarga é de R$ 50,00, e o máximo permitido é de R$ 5.000,00. Os créditos serão adicionados à plataforma imediatamente após a transação. Em caso de dúvidas, entre em contato com o suporte.
                                    <div style={{ margin: '25px 0 0 0', fontSize: '13px' }}>
                                        Valor da Recarga
                                        <Input
                                            value={valor}
                                            width="200px"
                                            onChange={setValor}
                                            type="dinheiro-v2"
                                        />
                                    </div>
                                </div>
                                </div>
                            </motion.div>
                        )}
                        {step === 2 && (
                            <motion.div
                                key="step2"
                                initial="enter"
                                animate="center"
                                exit="exit"
                                variants={slideVariants}
                                transition={{ duration: 0.5 }}
                                className="conteudo"
                            >
                                <div className="textenado">
                                <div className='texto-titulo-credito'>
                                    Resumo Recarga
                                </div>
                                <div className='texto-bloco-credito'>
                                    <div className='abraca-itens'>
                                        <div className='texto-itens'>
                                            <div className="linha-resumo">
                                                <div className="linha-titulo-resumo">
                                                    Valor da Recarga: 
                                                </div>
                                                <div className='resposta'>
                                                    {
                                                        parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                    }
                                                </div>
                                            </div>

                                            <div className="linha-resumo">
                                                <div className="linha-titulo-resumo">
                                                Taxa Administrativa:
                                                </div>
                                                <div className='resposta'>
                                                    {
                                                        ((parseFloat(valor)*9)/100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className='icone-texto'>
                                            <Icon sx={{ fontSize: 250, color:"#723172" }}>receipt_long</Icon>
                                        </div>
                                    </div>

                                    <div className="linha-resumo">
                                        <div className="linha-final-resumo">
                                        Valor adicionado em créditos: 
                                        </div>
                                        <div style={{marginTop:'20px'}}>
                                            {
                                                parseFloat(valorTotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                            }
                                        </div>
                                    </div>

                                </div>
                                </div>
                            </motion.div>
                        )}
                        {step ===  3 && (
                            <motion.div
                                key="step3"
                                initial="enter"
                                animate="center"
                                exit="exit"
                                variants={slideVariants}
                                transition={{ duration: 0.5 }}
                                className="conteudo"
                            >
                                <div className="qr-code-credito">
                                    <div style={{color:'#6B4380', fontSize:'20px', marginBottom:'30px'}}>
                                        Pagar com PIX
                                    </div>
                                    
                                    <div>
                                        {pagamento !== '' && pagamento !== null && pagamento !== undefined &&
                                            <div style={{display:'flex' , flexDirection:'column', justifyContent: 'center', alignItems:'center'}}>
                                                <div style={{display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center'}}>
                                                    <img src={"data:image/png;base64," +  pagamento.image_base64}  width={300} height={300}/>
                                                    <Button onClick={() => {lidarCopiarQrCode()}} width={"150px"} color={"#FEFCFF"} background={'#6B4380'} border={'1px solid #6B4380'} text={"PIX - Copia e cola"} />
                                                </div>
                                                <div style={{display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center', marginTop:'10px'}}>
                                                    {tempoRestante > 0 && (
                                                        <div className="temporizador">
                                                            Tempo restante: {formatarTempo(tempoRestante)}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        } 
                                        {confimaPagemento === true &&
                                           (
                                            <AnimatePresence> 
                                                <motion.div
                                                    initial={{ opacity: 0, scale: 0.9 }}
                                                    animate={{ opacity: 1, scale: 1 }}
                                                    exit={{ opacity: 0, scale: 0.9 }}
                                                    transition={{ duration: 0.3 }}
                                                >
                                                    <div style={{display:'flex' , flexDirection:'column', justifyContent: 'center', alignItems:'center'}}>
                                                        <div style={{display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center'}}>
                                                            <Icon sx={{ fontSize: 200, color:"rgb(66, 175, 60)" }}>done_all</Icon>
                                                            <div  style={{color:"rgb(66, 175, 60)", fontWeight: "bold", fontFamily: "sans-serif"}}> Recarga Confirmada</div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            </AnimatePresence>
                                           )
                                        }
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <div className="botoes-popup-credito">
                        {step === 1 && (
                            <div className='botoes-credito'>
                                <Button 
                                    onClick={()=>{setTrigger(false)}} 
                                    width={"70px"} 
                                    color={"#ffffff"} 
                                    background={'#d0b8ce'} 
                                    border={'#d0b8ce'} 
                                    text={"Voltar"} 
                                />
                                <Button
                                    onClick={ ()=>{lidarComConfimacao()}}
                                    width="70px"
                                    color="#ffffff"
                                    background="#723172"
                                    border="#723172"
                                    text="Avançar"
                                />
                            </div>

                        )}
                        {step === 2 && (
                            <div className='botoes-credito'>
                                <Button 
                                    onClick={()=>{previousStep()}} 
                                    width={"70px"} 
                                    color={"#ffffff"} 
                                    background={'#d0b8ce'} 
                                    border={'#d0b8ce'} 
                                    text={"Voltar"} 
                                />
                                <Button
                                    onClick={ ()=>{lidarPagamento()}}
                                    width="70px"
                                    color="#ffffff"
                                    background="#723172"
                                    border="#723172"
                                    text="Pagar"
                                />
                            </div>
                        )}
                    </div>
                    </div>
                </div>
            </div>
        </PopUp>
    );
};

export default PopUpCredito;
