import React from "react";
import "./home.scss"
import Layout from "../../componentes/layout";

import { Icon } from '@mui/material';

const Home = () => {
  
    return (
        <div className="tela-principal">
          {/*<div className="icone-pagina-home">
            <Icon  className="icon-engineering">engineering</Icon>
          </div>
          <div className="texto-pagina-home">
            Página em desenvolvimento
          </div>*/}
        </div>
    );
  };


  const PaginaHome = () => {

    return <>
      <Layout>
        <Home />
      </Layout>
    
    </>
  
  }
  
  export default PaginaHome;